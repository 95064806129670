// modules
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as yup from 'yup';

// mui
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// components
import { LanguageSelector } from '../screens/editorScreen/audioEditorPage/rightPanel/languageSelector/LanguageSelector';

// helpers
import { createRawArticle } from '../../helpers/articles/createRawArticle';

// interfaces
import { Selector } from "../../interfaces/Selector.interface";

interface Props {
    handleModalClose: () => void
}

export const RawArticlePopup = ({ handleModalClose }: Props) => {
    const theme = useTheme();

    // state selector
    const selectedLanguage = useSelector((state: Selector) => state.article.editor.selectedLanguage);

    const initialFormValues = {
        articleFileName: '',
        articleTitle: '',
        articleContent: ''
    }

    const validationShape = {
        articleFileName: yup
            .string()
            .min(1)
            .required('File name is required'),
        articleTitle: yup
            .string()
            .min(0),
        articleContent: yup
            .string()
            .min(1)
            .required('Content is required')
    }

    const validationSchema = yup.object().shape(validationShape);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialFormValues,
        validationSchema,
        onSubmit: (values: any) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = (values: any) => {
        const rawArticle = {
            name: values.articleFileName,
            title: values.articleTitle,
            content: values.articleContent,
            contentHtml: values.articleContent,
            language: selectedLanguage
        }
        createRawArticle(rawArticle).then(resp => {
            handleModalClose()
            if (resp.status === 200 || resp.status === 201) {
                Swal.fire('Success', 'Article created successfully', 'success');
            } else {
                Swal.fire('Error', `There was a problem trying to create the file`, 'error');
            }
        })
    }

    return (
        <Box
            component='div'
            data-test="rawcreation-modal"
            sx={{ minWidth: '85vw' }}
        >
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Stack spacing={2}>
                    <Typography
                        data-test="rawcreation-label"
                        color={theme.palette.primary.main}
                        component='div'
                        variant='h6'
                    >
                        Create an article and run the semantic analysis
                    </Typography>

                    <TextField
                        id="articleFileName"
                        name="articleFileName"
                        data-test="rawcreation-input-filename"
                        label="File name"
                        variant="outlined"
                        autoComplete="off"
                        value={formik.values.articleFileName}
                        onChange={formik.handleChange}
                        error={formik.touched.articleFileName && Boolean(formik.errors.articleFileName)}
                    />

                    <TextField
                        id="articleTitle"
                        name="articleTitle"
                        data-test="rawcreation-input-title"
                        label="Article Title"
                        variant="outlined"
                        autoComplete="off"
                        value={formik.values.articleTitle}
                        onChange={formik.handleChange}
                        error={formik.touched.articleTitle && Boolean(formik.errors.articleTitle)}
                    />

                    <TextField
                        id="articleContent"
                        name="articleContent"
                        data-test="rawcreation-input-content"
                        label="Article Content"
                        variant="outlined"
                        autoComplete="off"
                        value={formik.values.articleContent}
                        onChange={formik.handleChange}
                        error={formik.touched.articleContent && Boolean(formik.errors.articleContent)}
                        multiline
                        rows={18}
                    />

                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="center"
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <LanguageSelector />
                        <Button
                            variant="contained"
                            type="submit"
                            data-test="rawcreation-btn-save"
                            disabled={formik.isSubmitting}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleModalClose}
                            disabled={formik.isSubmitting}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </form>

        </Box>
    )
}
