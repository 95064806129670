import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import Header from '../editorScreen/header/Header';
import { BackgroundsTable } from './backgroundsTable/BackgroundsTable';
import { ConfigItem } from './configItem/ConfigItem';
import { ConsumptionsTable } from './consumptionTable/ConsumptionTable';
import { JinglesTable } from './jinglesTable/JinglesTable';
import { LexiconTable } from './lexiconTable/LexiconTable';
import { ListeningsTable } from './listeningsTable/ListeningsTable';
import { VoicesTable } from './voicesTable/VoicesTable';

// actions
import { loadConfig } from '../../../actions/config.actions';

// mui
import { CssBaseline, Toolbar, Container, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// interfaces
import { Selector } from '../../../interfaces/Selector.interface';

export const ConfigScreen = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const config = useSelector((state: Selector) => state.config.config);
    const selectedConfig = useSelector((state: Selector) => state.config.selectedConfig);
    const [loadingConfig, setLoadingConfig] = useState(false);

    // effect to load config on user change
    useEffect(() => {
        if (!loadingConfig) setLoadingConfig(true);
        // eslint-disable-next-line
    }, [selectedConfig]);

    useEffect(() => {
        if (loadingConfig) {
            loadConfig().then(action => {
                dispatch(action);
                setLoadingConfig(false);
            });
        }
    }, [dispatch, loadingConfig, selectedConfig]);

    return (
        <Container maxWidth={false}>
            <CssBaseline />
            <Container maxWidth="lg">
                <Header title="Config Management" />
            </Container>

            <Toolbar
                sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    mb: theme.spacing(2),
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0, sm: 4 }}>
                    {config?.map(
                        item =>
                            (item?.name?.toLowerCase() === 'lexicon' ||
                                item?.name?.toLowerCase() === 'voices' ||
                                item?.name?.toLowerCase() === 'jingles' ||
                                item?.name?.toLowerCase() === 'backgrounds' ||
                                item?.name?.toLowerCase() === 'consumptions' ||
                                item?.name?.toLowerCase() === 'listenings') && (
                                <ConfigItem key={`${item.name}-tree-item`} item={item} />
                            ),
                    )}
                </Stack>
            </Toolbar>

            {selectedConfig.toLowerCase() === 'lexicon' && <LexiconTable />}
            {selectedConfig.toLowerCase() === 'voices' && <VoicesTable />}
            {selectedConfig.toLowerCase() === 'backgrounds' && <BackgroundsTable />}
            {selectedConfig.toLowerCase() === 'jingles' && <JinglesTable />}
            {selectedConfig.toLowerCase() === 'consumptions' && <ConsumptionsTable />}
            {selectedConfig.toLowerCase() === 'listenings' && <ListeningsTable />}
        </Container>
    );
};
