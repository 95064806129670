import React, { useState } from 'react';

// mui
import {
    Box,
    Grid,
    Tab,
    Tabs,
    Toolbar,
    Typography,
} from '@mui/material';

// components
import { LanguageSelector } from './languageSelector/LanguageSelector';
import { RightPanelClose } from '../audioEditorHeader/rightPanelButtons/RightPanelClose';
import { ThesaurusPanel } from '../../thesaurusPanel/ThesaurusPanel';
import { VoiceSelector } from './voiceSelector/VoiceSelector'
import BackgroundMusicSelector from './audioSelectors/backgroundMusicSelector/BackgroundMusicSelector';
import EmotionSelector from './emotionSelector/EmotionSelector';
import ModelSelector from './modelSelector/modelSelector';
import IntroMusicSelector from './audioSelectors/introMusicSelector/IntroMusicSelector';
import IntroSelector from "./introSelector/IntroSelector";
import OutroMusicSelector from './audioSelectors/outroMusicSelector/OutroMusicSelector';
import SettingSlider from './settingSlider/SettingSlider';
import TextTagsSelector from './textTagsSelector/TextTagsSelector';
import TitleMusicSelector from './audioSelectors/titleMusicSelector/TitleMusicSelector';

// styles
import { AudioEffectsGrid, VoiceSettingsGrid } from '../styles/RightPanelStyle';


interface TabPanelProps {
    children: any,
    index: number,
    value: number
}


const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <span
            aria-labelledby={`right-tab-${index}`}
            hidden={value !== index}
            id={`right-tab-panel-${index}`}
            key={`right-tab-panel-${index}`}
            role="tabpanel"
            {...other}
        >
            {value === index && (
                <Box component='div' sx={{ p: 3 }}>
                    <Typography component='div'>{children}</Typography>
                </Box>
            )}
        </span>
    );
}


const tabProps = (index: number) => {
    return {
        id: `right-panel-tab-${index}`,
        'aria-controls': `right-panel-tab-${index}`,
    };
}


interface Props {
    open: boolean,
    handleDrawerClose: () => void
}

export const RightPanel = React.memo(({ open, handleDrawerClose }: Props) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };


    return (
        <>
            <Toolbar />

            <Box sx={{ overflow: 'auto' }}>

                <Box sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                >
                    <Tabs
                        aria-label="rightpanel-tabs"
                        data-test="rightpanel-tabs"
                        value={value}
                        onChange={handleChange}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Tab label="Voice" data-test="rightpanel-tab-voices" {...tabProps(0)} />
                        <Tab label="Audio" data-test="rightpanel-tab-audio" {...tabProps(1)} />
                        <Tab label="Lexicon" data-test="rightpanel-tab-lexicon" {...tabProps(2)} />
                    </Tabs>

                    <RightPanelClose open={open} handleDrawerClose={handleDrawerClose} />

                </Box>

                {/* voice settings and sound effects settings panel */}
                <TabPanel value={value} index={0}>
                    <VoiceSettingsGrid container>
                        <Grid item xs={12}>
                            <LanguageSelector />
                        </Grid>

                        <Grid item xs={12} sx={{ mt: '15px' }}>
                            <VoiceSelector />
                        </Grid>

                        <Grid item xs={12} sx={{ mt: '20px' }}>
                            <Box sx={{ textAlign: 'center', width: '100%' }}>
                                <SettingSlider type='rate' />
                                <SettingSlider type='pitch' />
                                <SettingSlider type='volume' />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: '15px' }}>
                            <Box sx={{ textAlign: 'center', width: '100%' }}>
                                <EmotionSelector />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: '10px' }}>
                            <Box sx={{ textAlign: 'center', width: '100%' }}>
                                <TextTagsSelector />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: '15px' }}>
                            <Box sx={{ textAlign: 'center', width: '100%' }}>
                                <ModelSelector />
                            </Box>
                        </Grid>

                    </VoiceSettingsGrid>
                </TabPanel>

                {/* audio settings panel */}
                <TabPanel value={value} index={1}>
                    <AudioEffectsGrid>
                        <Grid item xs={12}>
                            <IntroMusicSelector />
                            <TitleMusicSelector />
                            <OutroMusicSelector />
                            <BackgroundMusicSelector />
                            <IntroSelector />
                        </Grid>
                    </AudioEffectsGrid>
                </TabPanel>

                {/* thesaurus panel */}
                <TabPanel value={value} index={2}>
                    <ThesaurusPanel />
                </TabPanel>
            </Box>
        </>
    )
})