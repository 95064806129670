// modules
import Swal from 'sweetalert2';
import { AnyAction } from 'redux';

// custom action creator
import { actionCreator } from './actionCreator/actionCreator';

// types
import { types } from './actionTypes/actionTypes';
import { LexiconEntry } from '../models/lexiconEntry.model';
import { Jingle } from "../models/jingle.model";
import { Background } from "../models/background.model";
import { VoiceToSave } from '../interfaces/article/Voice.interface';

// helpers
import api from "../helpers/services/api";


export const loadConfig = async (): Promise<AnyAction> => {
    try {
        const respLexicon = await api.config.getLexicon();
        const respVoices = await api.config.getVoices();
        const respJingles = await api.config.getJingles();
        const respBackgrounds = await api.config.getBackgrounds();
        const respConsumptions = await api.config.getConsumptions();
        const respListenings = await api.config.getListenings();

        if (
            respLexicon.status === 200 &&
            respVoices.status === 200 &&
            respJingles.status === 200 &&
            respBackgrounds.status === 200 &&
            respConsumptions.status === 200 &&
            respListenings.status === 200
        ) {
            const lexicon = await respLexicon.json();
            const voices = await respVoices.json();
            const jingles = await respJingles.json();
            const backgrounds = await respBackgrounds.json();
            const consumptions = await respConsumptions.json();
            const listenings = await respListenings.json();

            const tabs: { index: number; name: string; data: object }[] = [];
            tabs.push({ index: 1, name: "Lexicon", data: lexicon.data });
            tabs.push({ index: 2, name: "Voices", data: voices.data });
            tabs.push({ index: 3, name: "Jingles", data: jingles.data });
            tabs.push({ index: 4, name: "Backgrounds", data: backgrounds.data });
            tabs.push({ index: 5, name: "Consumptions", data: consumptions.data });
            tabs.push({ index: 6, name: "Listenings", data: listenings.data });

            const config = { config: tabs };

            return actionCreator(types.configLoad, config);
        } else {
            const errorMsg = 'Errors in loadConfig action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = 'Errors in loadConfig action';
        console.error(errorMsg, error);
        throw new Error(errorMsg);
    }
}


export const setConfigFile = (selectedConfig: string): AnyAction => {
    return actionCreator(types.configSetSelected, selectedConfig)
}

// add a new voice
export const addVoice = async (voice: VoiceToSave): Promise<AnyAction> => {
    const resp = await api.voice.add(voice);

    if (resp.status === 200 || resp.status === 201) {
        Swal.fire('Success', 'Voice was created!', 'success');
        return setConfigFile('Voices');
    }
    else {
        const errorMsg = 'Something went wrong creating the voice';
        Swal.fire('Error', errorMsg, 'error');
        throw new Error(errorMsg);
    }
}

// update an existing voice
export const updateVoice = async (voiceId: number, voice: VoiceToSave): Promise<AnyAction> => {
    const resp = await api.voice.update(voiceId, voice);

    if (resp.status === 200 || resp.status === 201) {
        Swal.fire('Success', 'Voice was saved!', 'success');
        return setConfigFile('Voices');
    }
    else {
        const errorMsg = 'Something went wrong saving the voice';
        Swal.fire('Error', errorMsg, 'error');
        throw new Error(errorMsg);
    }
}

// delete a voice
export const deleteVoice = async (voiceId: number): Promise<AnyAction> => {
    const resp = await api.voice.delete(voiceId);

    if (resp.status === 200 || resp.status === 201) {
        Swal.fire('Success', 'Voice was deleted!', 'success');
        return setConfigFile('Voices');
    }
    if (resp.status === 409) {
        Swal.fire('Error', 'This voice is in use and cannot be deleted', 'error');
        return loadConfig();
    }
    else {
        const errorMsg = 'Something went wrong deleting the voice';
        Swal.fire('Error', errorMsg, 'error');
        throw new Error(errorMsg);
    }
}


// Lexicon

export const saveLexiconEntry = async (lexiconEntry: LexiconEntry): Promise<AnyAction> => {
    const resp = await api.lexicon.save(lexiconEntry);

    if (resp.status === 200 || resp.status === 201) {
        Swal.fire('Success', "Replacement Text saved!", 'success');
        return setConfigFile('Lexicon');
    }
    else {
        const errorMsg = 'Something went wrong saving lexicon entries'
        Swal.fire('Error', errorMsg, 'error');
        throw new Error(errorMsg)
    }
}

export const deleteLexiconEntry = async (lexiconEntryId: number): Promise<AnyAction> => {
    const resp = await api.lexicon.delete(lexiconEntryId);

    if (resp.status === 200 || resp.status === 201) {
        return setConfigFile('Lexicon');
    }
    else {
        const errorMsg = 'Something went wrong deleting the lexicon entry'
        Swal.fire('Error', errorMsg, 'error');
        throw new Error(errorMsg)
    }
}

// jingles

export const saveAllJingles = async (jingleList: any): Promise<AnyAction> => {
    const resp = await api.jingle.saveAll(jingleList);

    if (resp.status === 200 || resp.status === 201) {
        return setConfigFile('Jingles');
    } else {
        const errorMsg = 'Something went wrong saving the jingles'
        Swal.fire('Error', errorMsg, 'error');
        throw new Error(errorMsg)
    }

}

export const saveJingle = async (jingle: Jingle): Promise<AnyAction> => {
    const resp = await api.jingle.save(jingle);

    if (resp.status === 200 || resp.status === 201) {
        return setConfigFile('Jingles');
    } else {
        const errorMsg = 'Something went wrong saving the jingle'
        Swal.fire('Error', errorMsg, 'error');
        throw new Error(errorMsg)
    }
}

export const deleteJingle = async (jingleId: number): Promise<AnyAction> => {
    const resp = await api.jingle.delete(jingleId);

    if (resp.status === 200 || resp.status === 201) {
        return setConfigFile('Jingles');
    } else {
        const errorMsg = 'Something went wrong deleting the jingle'
        Swal.fire('Error', errorMsg, 'error');
        throw new Error(errorMsg)
    }
}

// backgrounds

export const saveAllBackgrounds = async (backgroundList: any): Promise<AnyAction> => {
    const resp = await api.background.saveAll(backgroundList);

    if (resp.status === 200 || resp.status === 201) {
        return setConfigFile('Backgrounds');
    } else {
        const errorMsg = 'Something went wrong saving the backgrounds'
        Swal.fire('Error', errorMsg, 'error');
        throw new Error(errorMsg)
    }

}

export const saveBackground = async (background: Background): Promise<AnyAction> => {
    const resp = await api.background.save(background);

    if (resp.status === 200 || resp.status === 201) {
        return setConfigFile('Backgrounds');
    } else {
        const errorMsg = 'Something went wrong saving the background'
        Swal.fire('Error', errorMsg, 'error');
        throw new Error(errorMsg)
    }
}

export const deleteBackground = async (backgroundId: number): Promise<AnyAction> => {
    const resp = await api.background.delete(backgroundId);

    if (resp.status === 200 || resp.status === 201) {
        return setConfigFile('Background');
    } else {
        const errorMsg = 'Something went wrong deleting the background'
        Swal.fire('Error', errorMsg, 'error');
        throw new Error(errorMsg)
    }
}


export const configLogout = () => {
    return actionCreator(types.configLogout);
}
