// modules
import {useDispatch, useSelector} from "react-redux";

// interfaces
import { Article } from "../../../../../../../interfaces/article/Article.interface";
import { Selector } from "../../../../../../../interfaces/Selector.interface";
import Swal from "sweetalert2";
import {setActiveArticle} from "../../../../../../../actions/articles.actions";
import {unpublishArticleAusha} from "../../../../../../../helpers/articles/unpublishArticleAusha";
import {AudioActionButton} from "../AudioActionButton";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import { MediaFile } from "../../../../../../../interfaces/article/MediaFile.interface";

export const AudioActionButtonUnpublishAusha = () => {
    const dispatch = useDispatch();

    const article = useSelector((state: Selector) => state.article);
    const { activeArticle, editor } = article;

    const handleUnpublishArticleAusha = () => {
        Swal.fire({
            title: 'Are you sure you want to unpublish this article from Ausha ?',
            icon:'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        }).then(result => {
            if(result.isConfirmed && editor.audioFile.length > 0){
                const articleBeforeUpdate: Article = { ...activeArticle};
                const articleId = activeArticle.id || -1;

                unpublishArticleAusha(articleId)
                    .then(() => {
                        const updatedAudioMediaFile: MediaFile | null = articleBeforeUpdate.audioMediaFile == null ? null 
                                                    : {...articleBeforeUpdate.audioMediaFile, aushaPodcastId : null};

                        dispatch(setActiveArticle({
                            ...articleBeforeUpdate,
                            audioMediaFile: updatedAudioMediaFile
                        }));
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
        })
    }
    return <AudioActionButton
        data_test="audioactionbuttun-btn-unpublishausha"
        title="Unpublish from Ausha"
        icon={<CancelScheduleSendIcon fontSize="small" /> }
        onClick={handleUnpublishArticleAusha}
        name="Unpublish from Ausha"
        color="warning"
    />

}