import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// mui
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// actions
import { partsUpdate } from "../../../../../../actions/parts.actions";
import { editorPartUpdate } from "../../../../../../actions/articles.actions";

import { Selector } from "../../../../../../interfaces/Selector.interface";
import { filterPossibleValues } from '../../../../../../helpers/emotionFilters';
import { ArticlePart } from '../../../../../../interfaces/article/ArticlePart.interface';

/**
 * @constant
 * Array of elements that represent the elevenlabs models available values. 
 */
const possibleModelsFile = [
    { value: "eleven_multilingual_v2", availableTTS: ["elevenlabs"] },
    { value: "eleven_turbo_v2_5", availableTTS: ["elevenlabs"] }
];

/**
 * @function
 * EmotionSelector component. Renders voice emotion styles and emotion degrees dropdown lists depending on the
 * currently selected TTS provider in the panel.
 * @returns JSX: two filtered dropdown lists. One to choose emotion styles and the other to choose emotion degrees.
 */
export default function ModelSelector() {
    const dispatch = useDispatch();

    // state selector
    const parts = useSelector((state: Selector) => state.parts);
    const selectedPart = useSelector((state: Selector) => state.article.editor.selectedPart);
    const voices = useSelector((state: Selector) => state.voices);

    // Selected voice from voiceSelector.
    const selectedVoice = parts[selectedPart]?.voice;

    // Selected model either from seleted part if set, or if not, from the voice itself
    const [model, setModel] = useState<string | null>(parts[selectedPart]?.ttsModel ?? parts[selectedPart]?.voice.ttsModel ?? null);
    const [modelsToBeRendered, setModelsToBeRendered] = useState([]);


    useEffect(() => {
        if (voices.voiceList.length > 0) {
            // Filter to keep only the selected voice and its attributes
            let selectedVoice = voices.voiceList.find(element => element?.name === parts[selectedPart]?.voice?.name)

            if (selectedVoice?.TTS.includes("elevenlabs")) {

                setModelsToBeRendered(filterPossibleValues(possibleModelsFile, selectedVoice?.TTS, selectedVoice));

                if (parts[selectedPart]?.ttsModel) {
                    setModel(parts[selectedPart]?.ttsModel);
                } else if (parts[selectedPart]?.voice.ttsModel) {
                    setModel(parts[selectedPart]?.voice.ttsModel);
                } else {
                    setModel("eleven_turbo_v2_5");
                }
            } else {
                setModelsToBeRendered([]);
                setModel(null);

                if (parts[selectedPart]?.ttsModel) {
                    const updatedParts: ArticlePart[] = parts.map(p => p.order === selectedPart
                        ? {
                            ...p,
                            ttsModel: null,
                        }
                        : p
                    )

                    dispatch(partsUpdate(updatedParts));
                    dispatch(editorPartUpdate(true));
                }
            }

        }
    }, [selectedPart, voices, selectedVoice, model, parts, dispatch]);



    const handleModelChange = (e: any) => {
        const modelValue = e.target.value
        setModel(modelValue);

        const updatedParts: ArticlePart[] = parts.map(p => p.order === selectedPart
            ? {
                ...p,
                ttsModel: modelValue,
            }
            : p
        )

        dispatch(partsUpdate(updatedParts));
        dispatch(editorPartUpdate(true));
    };


    return (
        <>
            {
                modelsToBeRendered?.length > 0 &&

                <Box sx={{ textAlign: 'left' }}>
                    <FormControl fullWidth>
                        <InputLabel id="ttsModelInputLabel">Model</InputLabel>
                        <Select
                            labelId={"ttsModelLabel"}
                            id={"ttsModel"}
                            value={model}
                            label={"Model"}
                            onChange={handleModelChange}
                        >
                            {modelsToBeRendered}
                        </Select>
                    </FormControl>
                </Box>
            }
        </>
    );
};
