import React, { useEffect, useState, useDeferredValue } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Flag from 'react-world-flags';

// mui
import { Box, Checkbox, CircularProgress, Divider, Stack, Typography, useTheme } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ArticleIcon from '@mui/icons-material/Article';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import SendIcon from '@mui/icons-material/Send';

// components
import { AudioFileItem } from './audioFileItem/AudioFileItem';
import { ArticleCreationButtons } from './articleCreationButtons/ArticleCreationButtons';
import { DateFilterButtons } from './dateFilterButtons/DateFilterButtons';
import { ParentItemLabel } from './audioFileItem/parentItemLabel/ParentItemLabel';
import { SearchInput } from './searchInput/SearchInput';

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';

// actions
import { ArticleSet, ArticleVersion, getArticlesAsSets } from '../../../../../helpers/articleSet';
import { getFlagCode } from '../../../../../data/languageLabels';
import { Article } from '../../../../../interfaces/article/Article.interface';
import { updateFiles } from '../../../../../actions/files.actions';

const NoFilesLabel = () => {
    const theme = useTheme();
    return (
        <Box sx={{ p: theme.spacing(1) }}>
            <Typography color={theme.palette.text.secondary} component="div" variant="caption">
                No files match the current filters
            </Typography>
        </Box>
    );
};

export const AudioFilesTab = React.memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const files = useSelector((state: Selector) => state.files);

    const [expanded, setExpanded] = useState(['files', 'published']);

    const [filesList, setFilesList] = useState<ArticleSet[]>([]);
    const [publishedList, setPublishedList] = useState<ArticleSet[]>([]);
    const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
    const [isSetChecked, setIsSetChecked] = useState<Map<number, boolean>>(new Map());

    const deferredFilesList = useDeferredValue(filesList);
    const deferredPublishedList = useDeferredValue(publishedList);

    const handleTreeItemNodeToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const handleSelectSet = (items: ArticleSet) => {
        const isSelected = isSetChecked.get(items.getFirstArticle()?.id || 0);
        if (isSelected !== undefined) {
            let updatedFiles: Article[] = [...files];

            isSetChecked.set(items.getFirstArticle()?.id || 0, !isSelected);
            setIsSetChecked(new Map(isSetChecked));

            for (let articleVersion of items.articlesVersions) {
                for (let articleLanguage of articleVersion.articleLanguages) {
                    const article = files.filter(article => article.id === articleLanguage.getArticle().id)[0];

                    updatedFiles = updatedFiles.map(a => {
                        if (a.id !== article.id) return a;
                        return { ...article, isSelected: !isSelected };
                    });
                }
            }
            dispatch(updateFiles(updatedFiles));
        }
    };

    const deselectSet = (articleId: number) => {
        const set = deferredFilesList.find((set: ArticleSet) =>
            set.articlesVersions.find((articleVersion: ArticleVersion) =>
                articleVersion.articleLanguages.find(articleLanguage => articleLanguage.getArticle().id === articleId),
            ),
        );
        if (set) {
            const exist = isSetChecked.get(set.getFirstArticle()?.id || 0);
            if (exist !== undefined) {
                isSetChecked.set(set.getFirstArticle()?.id || 0, false);
                setIsSetChecked(new Map(isSetChecked));
            }
        }
    };

    // effect to set the files list
    useEffect(() => {
        const filesList = files
            .filter(
                file =>
                    (file.status === 'CREATING' || file.status === 'PENDING' || file.status === 'DRAFT') &&
                    file.visible,
            )
            .sort((a, b) => a.fileName.localeCompare(b.fileName));

        const articlesSet = getArticlesAsSets(filesList);
        for (let articleSet of articlesSet) {
            const exist = isSetChecked.get(articleSet.getFirstArticle()?.id || 0);
            if (exist === undefined) {
                setIsSetChecked(isSetChecked.set(articleSet.getFirstArticle()?.id || 0, false));
            }
        }
        setFilesList(articlesSet);

        // published list
        const publishedList = files
            .filter(file => file.status === 'PUBLISHED' && file.visible)
            .sort((a, b) => a.fileName.localeCompare(b.fileName));

        const publishedArticlesSet = getArticlesAsSets(publishedList);
        setPublishedList(publishedArticlesSet);

        // eslint-disable-next-line
    }, [files]);

    return (
        <>
            <Box
                id="audio-tab-header"
                sx={{
                    backgroundColor: 'background.paper',
                    borderBottom: 1,
                    borderColor: 'divider',
                    mb: theme.spacing(2),
                    position: 'sticky',
                    pt: theme.spacing(1),
                    top: 73,
                    zIndex: 1,
                    width: '100%',
                }}
            >
                <ArticleCreationButtons
                    isDeleteMode={isDeleteMode}
                    setIsDeleteMode={setIsDeleteMode}
                    deferredFilesList={deferredFilesList}
                />

                <SearchInput />

                <DateFilterButtons />
            </Box>

            <TreeView
                aria-label="audio-files-tab"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={handleTreeItemNodeToggle}
                key="audio-files-tab"
            >
                <TreeItem
                    data-test="audiotab-tree-files"
                    label={<ParentItemLabel Icon={ArticleIcon} text="FILES" />}
                    nodeId="files"
                    key="audio-tab-draft-files"
                >
                    {deferredFilesList.length === 0 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pr: 3, pt: 2 }}>
                            <CircularProgress size={20} />
                        </Box>
                    )}
                    {deferredFilesList.map((article: ArticleSet) => {
                        if (
                            article.articlesVersions.length > 0 &&
                            article.articlesVersions[0].articleLanguages.length > 0
                        ) {
                            if (article.articlesVersions.length > 1) {
                                return (
                                    <Box
                                        key={`audio-tab-draft-parent-file-box-${article.getFilename()}-${article.getFileId()}`}
                                    >
                                        <Divider sx={{ my: theme.spacing(0.3) }} />

                                        <Stack direction="row" sx={{ display: 'flex', alignItems: 'start' }}>
                                            {isDeleteMode && (
                                                <Checkbox
                                                    key={article.getFilename() + '-box'}
                                                    color="primary"
                                                    sx={{ p: 0, mr: '5px' }}
                                                    checked={isSetChecked.get(article.getFirstArticle()?.id || 0)}
                                                    onChange={() => handleSelectSet(article)}
                                                />
                                            )}
                                            <Box sx={{ ml: '-2px' }}>
                                                {article.getNumberOfLanguages() > 1 ? (
                                                    <LanguageIcon sx={{ color: '#333', fontSize: 20, mt: '8px' }} />
                                                ) : (
                                                    <Flag
                                                        id={'flag-img-article'}
                                                        code={getFlagCode(
                                                            article.getFirstArticle()?.language?.code || 'fr-FR',
                                                        )}
                                                    />
                                                )}
                                            </Box>
                                            <TreeItem
                                                key={`audio-tab-draft-parent-file-${article.getFilename()}-${article.getFileId()}`}
                                                nodeId={article.getFilename() || ''}
                                                label={
                                                    <Box
                                                        sx={{
                                                            py: expanded.includes(article.getFilename() || '') ? 0 : 1,
                                                        }}
                                                    >
                                                        {article.getFilename()}
                                                    </Box>
                                                }
                                                onClick={() => {
                                                    if (expanded.includes(article.getFilename() || '')) {
                                                        setExpanded(
                                                            expanded.filter(id => id !== article.getFilename()),
                                                        );
                                                    } else {
                                                        setExpanded([...expanded, article.getFilename() || '']);
                                                    }
                                                }}
                                                sx={{ width: '100%' }}
                                            >
                                                {article.articlesVersions.map((item: ArticleVersion) => (
                                                    <AudioFileItem
                                                        deleteMode={isDeleteMode}
                                                        deselectAction={deselectSet}
                                                        articleItem={item}
                                                        key={`audio-tab-draft-child-file-${
                                                            item.getArticleLanguages()[0].getArticle().id
                                                        }`}
                                                        showArticleName={false}
                                                    />
                                                ))}
                                            </TreeItem>
                                        </Stack>
                                    </Box>
                                );
                            } else {
                                return (
                                    <Box key={`audio-draft-file-box-${article.getFirstArticle()?.id}`}>
                                        <Divider sx={{ my: theme.spacing(0.3) }} />

                                        <AudioFileItem
                                            deleteMode={isDeleteMode}
                                            deselectAction={deselectSet}
                                            articleItem={article.articlesVersions[0]}
                                            key={`audio-draft-file-${article.getFirstArticle()?.id}`}
                                            showArticleName={true}
                                        />
                                    </Box>
                                );
                            }
                        } else {
                            return <NoFilesLabel />;
                        }
                    })}
                </TreeItem>

                <Divider key="audio-files-tab-divider" sx={{ my: theme.spacing(2) }} />

                <TreeItem
                    data-test="audiotab-tree-published"
                    key="audio-tab-published-files"
                    nodeId="published"
                    label={
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box sx={{ mr: '8px', display: 'flex', alignItems: 'center' }}>
                                <SendIcon />
                                &nbsp; PUBLISHED
                            </Box>
                        </Box>
                    }
                    sx={{ mb: theme.spacing(2) }}
                >
                    {deferredPublishedList.length === 0 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pr: 3, pt: 2 }}>
                            <CircularProgress size={20} />
                        </Box>
                    )}
                    {deferredPublishedList.map((article: ArticleSet) => {
                        if (
                            article.articlesVersions.length > 0 &&
                            article.articlesVersions[0].articleLanguages.length > 0
                        ) {
                            if (article.articlesVersions.length > 1) {
                                return (
                                    <TreeItem
                                        key={`audio-tab-published-parent-file-${article.getFilename()}-${article.getFileId()}`}
                                        nodeId={article.getFilename() ? article.getFilename()! : ''}
                                        label={article.getFilename()}
                                        onClick={() => {
                                            if (expanded.includes(article.getFilename() || '')) {
                                                setExpanded(expanded.filter(id => id !== article.getFilename()));
                                            } else {
                                                setExpanded([...expanded, article.getFilename() || '']);
                                            }
                                        }}
                                    >
                                        {article.articlesVersions.map((item: ArticleVersion) => (
                                            <AudioFileItem
                                                deleteMode={isDeleteMode}
                                                deselectAction={deselectSet}
                                                articleItem={item}
                                                key={`audio-tab-published-child-file-${
                                                    item.articleLanguages[0].getArticle().id
                                                }`}
                                                showArticleName={false}
                                            />
                                        ))}
                                    </TreeItem>
                                );
                            } else {
                                return (
                                    <AudioFileItem
                                        deleteMode={isDeleteMode}
                                        deselectAction={deselectSet}
                                        articleItem={article.articlesVersions[0]}
                                        key={`audio-published-file-${article.getFirstArticle()!.id}`}
                                        showArticleName={true}
                                    />
                                );
                            }
                        } else {
                            return <NoFilesLabel />;
                        }
                    })}
                </TreeItem>
            </TreeView>
        </>
    );
});
