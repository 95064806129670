import React, { useEffect, useCallback, useState } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import { ConfigScreen } from '../components/screens/configScreen/ConfigScreen';
import { FileScreen } from '../components/screens/filesScreen/FilesScreen';
import { LoadingPage } from '../components/screens/loadingScreen/LoadingPage';
import { LoginScreen } from '../components/screens/authScreen/LoginScreen';
import { MainPage } from '../components/screens/editorScreen/mainPage/MainPage';
import { UserEdit } from '../components/screens/usersScreen/userEdit/UserEdit';
import { UserScreen } from '../components/screens/usersScreen/userScreen/UserScreen';
import { VoiceForm } from '../components/screens/configScreen/voiceForm/VoiceForm';

// routes
import { AdminRoute } from './AdminRoute';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

// actions
import { loadSoundEffects } from '../actions/soundEffects.actions';
import { startChecking } from '../actions/auth.actions';
import { loadLanguages } from '../actions/language.actions';
import { readClients, readUsers } from '../actions/users.actions';

// interfaces
import { Selector } from '../interfaces/Selector.interface';
import { ThesaurusConfigScreen } from '../components/screens/configScreen/ThesaurusConfigScreen';

export const AppRouter = React.memo(() => {
    const dispatch = useDispatch();

    // state selector
    const auth = useSelector((state: Selector) => state.auth);

    const [loadingLanguages, setLoadingLanguages] = useState(false);
    const [loadingSoundEffects, setLoadingSoundEffects] = useState(false);
    const [loadingClients, setLoadingClients] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);

    const checkLogin = useCallback(() => startChecking().then(action => dispatch(action)), [dispatch]);

    // check the user login
    useEffect(() => {
        if (auth.checking) {
            checkLogin();
        }
    }, [auth.checking, checkLogin]);

    // load languages
    useEffect(() => {
        if (!auth.checking && auth.uid && !loadingLanguages) {
            setLoadingLanguages(true);
        }
        // eslint-disable-next-line
    }, [auth]);

    useEffect(() => {
        if (loadingLanguages) {
            loadLanguages().then(action => {
                dispatch(action);
                setLoadingLanguages(false);
            });
        }
    }, [dispatch, loadingLanguages]);

    // effect to load sound effects
    useEffect(() => {
        if (!auth.checking && auth.uid && !loadingSoundEffects) {
            setLoadingSoundEffects(true);
        }
        // eslint-disable-next-line
    }, [auth]);

    useEffect(() => {
        if (loadingSoundEffects) {
            loadSoundEffects().then(action => {
                dispatch(action);
                setLoadingSoundEffects(false);
            });
        }
    }, [dispatch, loadingSoundEffects]);

    // load clients
    useEffect(() => {
        if (!auth.checking && auth.uid && !loadingClients && auth.role.toLowerCase().includes('admin')) {
            setLoadingClients(true);
        }
        // eslint-disable-next-line
    }, [auth]);

    useEffect(() => {
        if (loadingClients) {
            readClients().then(action => {
                dispatch(action);
                setLoadingClients(false);
            });
        }
    }, [dispatch, loadingClients]);

    // load users
    useEffect(() => {
        const isImpersonated = auth.impersonatedBy > 0;
        if (
            !auth.checking &&
            auth.uid &&
            !loadingUsers &&
            (auth.role.toLowerCase().includes('admin') || isImpersonated)
        ) {
            setLoadingUsers(true);
        }
        // eslint-disable-next-line
    }, [auth]);

    useEffect(() => {
        if (loadingUsers) {
            const isImpersonated = auth.impersonatedBy > 0;
            let userRole = auth.role;
            if (isImpersonated) userRole = 'SuperAdmin';
            readUsers(userRole, isImpersonated).then(action => {
                dispatch(action);
                setLoadingUsers(false);
            });
        }
    }, [dispatch, loadingUsers, auth]);

    return (
        <>
            {auth.checking ? (
                <LoadingPage />
            ) : (
                <Router>
                    <div>
                        <Switch>
                            <PublicRoute
                                exact
                                path="/login"
                                component={LoginScreen}
                                isAuthenticated={!!auth.uid}
                                role={auth.role}
                            />
                            {auth.role !== 'ThesaurusAdmin' && (
                                <PrivateRoute exact path="/" component={MainPage} isAuthenticated={!!auth.uid} />
                            )}
                            {auth.role !== 'ThesaurusAdmin' && (
                                <PrivateRoute
                                    exact
                                    path="/voice-form/:voice"
                                    component={VoiceForm}
                                    isAuthenticated={!!auth.uid}
                                />
                            )}
                            {auth.role !== 'ThesaurusAdmin' && (
                                <PrivateRoute
                                    exact
                                    path="/voice-form/new"
                                    component={VoiceForm}
                                    isAuthenticated={!!auth.uid}
                                />
                            )}
                            {auth.role !== 'User' && auth.role !== 'ThesaurusAdmin' && (
                                <AdminRoute
                                    exact
                                    path="/users"
                                    component={UserScreen}
                                    isAuthenticated={!!auth.uid}
                                    role={auth.role}
                                />
                            )}
                            {auth.role !== 'ThesaurusAdmin' && (
                                <PrivateRoute exact path="/files" component={FileScreen} isAuthenticated={!!auth.uid} />
                            )}
                            {auth.role !== 'User' && auth.role !== 'ThesaurusAdmin' && (
                                <AdminRoute
                                    exact
                                    path="/users/edit/:id"
                                    component={UserEdit}
                                    isAuthenticated={!!auth.uid}
                                    role={auth.role}
                                />
                            )}
                            {auth.role !== 'ThesaurusAdmin' && (
                                <AdminRoute
                                    exact
                                    path="/config"
                                    component={ConfigScreen}
                                    isAuthenticated={!!auth.uid}
                                    role={auth.role}
                                />
                            )}
                            {auth.role === 'ThesaurusAdmin' && (
                                <AdminRoute
                                    exact
                                    path="/thesaurus"
                                    component={ThesaurusConfigScreen}
                                    isAuthenticated={!!auth.uid}
                                    role={auth.role}
                                />
                            )}
                            {auth.role !== 'ThesaurusAdmin' && <Redirect to="/" />}
                            {auth.role === 'ThesaurusAdmin' && <Redirect to="/thesaurus" />}
                        </Switch>
                    </div>
                </Router>
            )}
        </>
    );
});
