// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

// mui
import { Button, Select } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

// interfaces
import { Selector } from '../../../../../../interfaces/Selector.interface';

// actions
import { showPopupRawArticle } from '../../../../../../actions/popup.action';
import { closeArticle } from '../../../../../../actions/articles.actions';
import { updateDeletedArticleBatch } from '../../../../../../actions/files.actions';

// helpers
import { updateArticle } from '../../../../../../helpers/articles/updateArticle';
import { ArticleSet } from '../../../../../../helpers/articleSet';

interface Props {
    isDeleteMode: boolean;
    setIsDeleteMode: (isDeleteMode: boolean) => void;
    deferredFilesList: ArticleSet[];
}

export const ArticleCreationButtons = ({ isDeleteMode, setIsDeleteMode, deferredFilesList }: Props) => {
    const dispatch = useDispatch();

    // state selector
    const files = useSelector((state: Selector) => state.files);

    const isArticleSelectedAtAll = (): boolean => {
        for (let articleSet of deferredFilesList) {
            for (let articleVersion of articleSet.articlesVersions) {
                for (let articleLanguage of articleVersion.articleLanguages) {
                    if (articleLanguage.getArticle().isSelected) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    const updateArticleList = (articlesToDelete: number[]) => {
        const articlesIds: number[] = [];
        for (let id of articlesToDelete) {
            articlesIds.push(id);
        }
        dispatch(updateDeletedArticleBatch(articlesIds, files));
    };

    const deleteSelectedFiles = (articlesToDelete: number[]) => {
        for (let id of articlesToDelete) {
            updateArticle(id, { status: 'DELETED' });
        }
    };

    const handleDeleteMode = () => {
        if (isDeleteMode) {
            if (isArticleSelectedAtAll()) {
                Swal.fire({
                    title: 'Are you sure you want to delete these articles ?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                }).then(result => {
                    if (result.isConfirmed) {
                        setIsDeleteMode(false);

                        // Delete articles
                        const articlesToDelete: number[] = [];
                        for (const articleSet of deferredFilesList) {
                            for (const articleVersion of articleSet.articlesVersions) {
                                for (const articleLanguage of articleVersion.articleLanguages) {
                                    if (articleLanguage.getArticle().isSelected) {
                                        articlesToDelete.push(articleLanguage.getArticle().id);
                                    }
                                }
                            }
                        }

                        if (articlesToDelete.length > 0) {
                            updateArticleList(articlesToDelete);
                            deleteSelectedFiles(articlesToDelete);
                        }
                    }
                });
            } else {
                setIsDeleteMode(false);
            }
        } else {
            setIsDeleteMode(true);
        }
    };

    const handleCreateManualArticle = () => {
        Swal.fire({
            title: 'Are you sure you want to close this article to create a new one ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(closeArticle());
            }
        });
    };

    const handleCreateArticle = (e: any) => {
        switch (e.target.value) {
            case 'preprocessed':
                dispatch(showPopupRawArticle());
                break;
            case 'manual':
                handleCreateManualArticle();
                break;
            default:
                break;
        }
    };

    return (
        <ButtonGroup variant="outlined" aria-label="sidebar article creation buttons" orientation="horizontal">
            <Select
                labelId="audio-tab-creation-select"
                id="audio-tab-creation-select"
                key="audio-tab-creation-select"
                data-test="audiotab-select-creation"
                value="New"
                onChange={handleCreateArticle}
                sx={{ height: '2.3em', mb: 0.5, ml: 0, borderRadius: '4px 0 0 4px', borderRight: 'none' }}
            >
                <MenuItem value="New" key="new-article" sx={{ display: 'none' }}>
                    <NoteAddIcon fontSize="small" sx={{ margin: '0 0.5em 0 0' }} />
                    NEW FILE
                </MenuItem>
                <MenuItem data-test="creation-btn-preprocessed" key="preprocessed-article" value="preprocessed">
                    Preprocessed
                </MenuItem>
                <MenuItem data-test="creation-btn-manual" key="manual-article" value="manual">
                    Manual
                </MenuItem>
            </Select>

            <Button
                id="btn-delete-articles"
                data-test="audiotab-btn-delete"
                startIcon={<DeleteIcon />}
                color={isDeleteMode ? 'error' : 'primary'}
                sx={{ borderRadius: '0 4px 4px 0', height: '2.6em' }}
                onClick={handleDeleteMode}
            >
                {isDeleteMode ? 'Confirm' : 'Delete'}
            </Button>
        </ButtonGroup>
    );
};
