
// modules
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

//components
import {AudioActionButton} from "../AudioActionButton";


// mui
import SendIcon from "@mui/icons-material/Send";

// interfaces
import { Selector } from "../../../../../../../interfaces/Selector.interface";
import { Article } from "../../../../../../../interfaces/article/Article.interface";
import {publishArticleAusha} from "../../../../../../../helpers/articles/publishArticleAusha";
import {setActiveArticle} from "../../../../../../../actions/articles.actions";
import { MediaFile } from "../../../../../../../interfaces/article/MediaFile.interface";



export const AudioActionButtonPublishAusha = () => {
    const dispatch = useDispatch();

    const article = useSelector((state: Selector) => state.article);
    const { activeArticle, editor } = article;

    const handlePublishAusha = () => {
        Swal.fire({
            title: "Are you sure you want to publish this article on Ausha ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
        }).then(result => {
            if( result.isConfirmed && editor.audioFile.length > 0) {
                const articleBeforeUpdate: Article = { ...activeArticle };
                const articleId = activeArticle.id

                publishArticleAusha(articleId)
                    .then(async (resp: any) => {

                        const respBody = await resp.json();
                        const aushaPodcastId: number = respBody;

                        const updatedAudioMediaFile: MediaFile | null = articleBeforeUpdate.audioMediaFile == null ? null 
                            : {...articleBeforeUpdate.audioMediaFile, aushaPodcastId};
                        dispatch(setActiveArticle({
                            ...articleBeforeUpdate,
                            audioMediaFile: updatedAudioMediaFile
                        }));
                    })
                    .catch(error => {
                        console.error(error);
                    })
            }
        })
    }


    return <AudioActionButton
        data_test="audioactionbutton-btn-publishausha"
        title="Publish to Ausha"
        icon={<SendIcon fontSize="small" />}
        onClick={handlePublishAusha}
        name="Publish to Ausha"
    />
}