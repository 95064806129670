// react
import React, { useState, useEffect } from 'react';

// redux
import { useSelector } from 'react-redux';

// mui
import { Divider, useTheme } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ArticleIcon from '@mui/icons-material/Article';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';

// components
import { VideoFileItem } from './videoFileItem/VideoFileItem';

// interfaces
import { Article } from '../../../../../interfaces/article/Article.interface';
import { Selector } from '../../../../../interfaces/Selector.interface';

export const VideoFilesTab = React.memo(() => {
    const theme = useTheme();

    // state selector
    const files = useSelector((state: Selector) => state.files);

    const [expanded, setExpanded] = useState(['files', 'published']);
    const [filesList, setFilesList] = useState([]);
    const [publishedList, setPublishedList] = useState([]);

    const handleArticleVersion = (list: any[]) => {
        const newArray = list.reduce((acc, obj) => {
            const found = acc.find((arr: any[]) => arr[0] && arr[0].fileName === obj.fileName);
            if (found) {
                found.push(obj);
            } else {
                acc.push([obj]);
            }
            return acc;
        }, []);

        return newArray;
    };

    useEffect(() => {
        setFilesList(
            handleArticleVersion(
                files.filter(file => file.status === 'DRAFT').sort((a, b) => a.fileName.localeCompare(b.fileName)),
            ),
        );
        setPublishedList(
            handleArticleVersion(
                files.filter(file => file.status === 'PUBLISHED').sort((a, b) => a.fileName.localeCompare(b.fileName)),
            ),
        );
    }, [files]);

    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            key="filesTab"
            expanded={expanded}
            sx={{ flexGrow: 1, maxWidth: 300, overflowY: 'auto' }}
        >
            <TreeItem data-test="videotab-tree-files" nodeId="files" label="FILES" icon={<ArticleIcon />}>
                {filesList.map((items: Article[]) => {
                    if (items.length > 1) {
                        return (
                            <TreeItem
                                key={items[0].fileName}
                                nodeId={items[0].fileName}
                                label={items[0].fileName}
                                onClick={() => {
                                    if (expanded.includes(items[0].fileName)) {
                                        setExpanded(expanded.filter(id => id !== items[0].fileName));
                                    } else {
                                        setExpanded([...expanded, items[0].fileName]);
                                    }
                                }}
                            >
                                {items.map(item => {
                                    return (
                                        <VideoFileItem key={`file-${item.id}`} item={item} showArticleName={false} />
                                    );
                                })}
                            </TreeItem>
                        );
                    } else {
                        return <VideoFileItem key={`file-${items[0].id}`} item={items[0]} showArticleName={true} />;
                    }
                })}
            </TreeItem>

            <Divider key="video-files-tab-divider" sx={{ my: theme.spacing(2) }} />

            <TreeItem
                data-test="videotab-tree-published"
                nodeId="published"
                label="PUBLISHED"
                icon={<SendIcon />}
                sx={{ marginBottom: theme.spacing(2) }}
            >
                {publishedList.map((items: Article[]) => {
                    if (items.length > 1) {
                        return (
                            <TreeItem
                                key={items[0].fileName}
                                nodeId={items[0].fileName}
                                label={items[0].fileName}
                                onClick={() => {
                                    if (expanded.includes(items[0].fileName)) {
                                        setExpanded(expanded.filter(id => id !== items[0].fileName));
                                    } else {
                                        setExpanded([...expanded, items[0].fileName]);
                                    }
                                }}
                            >
                                {items.map(item => {
                                    return (
                                        <VideoFileItem key={`file-${item.id}`} item={item} showArticleName={false} />
                                    );
                                })}
                            </TreeItem>
                        );
                    } else {
                        return <VideoFileItem key={`file-${items[0].id}`} item={items[0]} showArticleName={true} />;
                    }
                })}
            </TreeItem>
        </TreeView>
    );
});
