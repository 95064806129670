import { types } from '../actions/actionTypes/actionTypes';
import { EditorContext } from '../interfaces/editor/EditorContext.interface';
import { editorInitialState } from '../state/editorInitialState';


const initialState = {
    ...editorInitialState
}

export const articlesReducer = (state = initialState, action: any): EditorContext => {
    switch (action.type) {
        case types.articlesClearActive:
            return {
                ...state,
                activeArticle: { ...editorInitialState.activeArticle }
            }

        case types.articlesSetActive:
            return {
                ...state,
                activeArticle: action.payload.data,
                editor: {
                    ...state.editor,
                    articleName: action.payload.data?.fileName || ''
                }
            }

        case types.articlesSetActiveStatus:
            return {
                ...state,
                activeArticle: {
                    ...state.activeArticle,
                    status: action.payload.data
                },
                editor: { ...state.editor }
            }

        case types.articlesSetActiveAudioGenerationStatus:
            return {
                ...state,
                activeArticle: {
                    ...state.activeArticle,
                    audioMediaFile: {
                        id: state.activeArticle.audioMediaFile?.id || 0,
                        durationMillis: state.activeArticle.audioMediaFile?.durationMillis || 0,
                        url: state.activeArticle.audioMediaFile?.url || '',
                        generationStatus: action.payload.data,
                        aushaPodcastId: state.activeArticle.audioMediaFile?.aushaPodcastId || 0
                    }
                },
                editor: { ...state.editor }
            }

        case types.articlesSetActiveVideoGenerationStatus:
            return {
                ...state,
                activeArticle: {
                    ...state.activeArticle,
                    videoMediaFile: {
                        id: state.activeArticle.audioMediaFile?.id || 0,
                        durationMillis: state.activeArticle.audioMediaFile?.durationMillis || 0,
                        url: state.activeArticle.audioMediaFile?.url || '',
                        generationStatus: action.payload.data,
                        aushaPodcastId: state.activeArticle.audioMediaFile?.aushaPodcastId || 0
                    }
                },
                editor: { ...state.editor }
            }

        case types.articlesSetActiveArticleLanguage:
            return {
                ...state,
                activeArticle: {
                    ...state.activeArticle,
                    language: action.payload.data
                },
                editor: { ...state.editor }
            }

        case types.articlesEditorPartUpdate:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    isModified: action.payload.data
                }
            }

        case types.articlesEditorPartActive:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    selectedPart: action.payload.data
                }
            }

        case types.articlesEditorSelectedLanguageUpdate:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    selectedLanguage: action.payload.data
                }
            }

        case types.articlesEditorActiveArticleName:
            return {
                ...state,
                activeArticle: {
                    ...state.activeArticle,
                    fileName: action.payload.data
                }
            }

        case types.articlesEditorActiveArticleId:
            return {
                ...state,
                activeArticle: {
                    ...state.activeArticle,
                    id: action.payload.data
                }
            }

        case types.articleEditorLastModified:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    articleLastModified: action.payload.data
                }
            }

        case types.articleEditorLastModifiedBy:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    articleLastModifiedBy: action.payload.data
                }
            }

        case types.articlesEditorAudioFile:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    audioFile: action.payload.data
                }
            }

        case types.articleEditorIsDownloadable:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    isDownloadable: action.payload.data
                }
            }

        case types.articlesEditorLoadAudioFile:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    loadAudioFile: action.payload.data
                }
            }

        case types.articlesEditorHasSyntaxError:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    hasSyntaxError: action.payload.data
                }
            };

        case types.articlesEditorHasPartWithSymbolsOnly:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    hasPartWithSymbolsOnly: action?.payload.data
                }
            };

        case types.articlesEditorChangeIntroMusic:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    introMusicId: action?.payload.data
                }
            };

        case types.articlesEditorChangeTitleMusic:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    titleMusicId: action?.payload.data
                }
            };

        case types.articlesEditorChangeOutroMusic:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    outroMusicId: action?.payload.data
                }
            };

        case types.articlesEditorChangeBackgroundMusic:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    backgroundMusicId: action?.payload.data
                }
            };

        case types.articlesEditorSetHighlightMode:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    isHighlightModeActivated: action?.payload.data
                }
            }

        case types.articlesClose:
            return initialState

        case types.articlesLogout:
            return initialState

        default:
            return state
    }
}