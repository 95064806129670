// modules
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as yup from 'yup';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';

// actions
import { loadAllFiles } from '../../actions/files.actions';

// interfaces
import { Article } from '../../interfaces/article/Article.interface';
import { Selector } from '../../interfaces/Selector.interface';

// helpers
import api from '../../helpers/services/api';
import { formatDateToString } from '../../helpers/formatDateToString';

interface Props {
    handleModalClose: () => void;
    article: Article | undefined;
}

export const EditArticleDisplayNamePopup = ({ handleModalClose, article }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const dateFilter = useSelector((state: Selector) => state.sidebar.dateFilter);

    const initialFormValues = {
        name: article?.publisherTitle ? article?.publisherTitle : '',
        publisher: article?.publisher ? article?.publisher : '',
        date: article?.publisherPublicationDate ? formatDateToString(article?.publisherPublicationDate) : '',
    };

    const validationShape = {
        name: yup.string().min(1).required('Article name is required'),
        publisher: yup.string().min(1).required('Publisher is required'),
        date: yup.string().min(1).required('Date is required'),
    };

    const validationSchema = yup.object().shape(validationShape);

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema,
        onSubmit: (values: any) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = (_: any) => {
        handleModalClose();

        if (!article) return;

        var newArticle = { ...article };

        newArticle.publisherTitle = formik.values.name;
        newArticle.publisher = formik.values.publisher;

        // Reverse date to be able to parse it
        var stringDate =
            formik.values.date.substring(3, 5) +
            '/' +
            formik.values.date.substring(0, 2) +
            '/' +
            formik.values.date.substring(6, 10) +
            ' ' +
            formik.values.date.substring(10);

        newArticle.publisherPublicationDate = Date.parse(stringDate);

        if (isNaN(newArticle.publisherPublicationDate)) {
            Swal.fire('Error', `Date is not valid`, 'error');
            return;
        }

        api.article.update(newArticle.id, newArticle).then(async resp => {
            if (resp.status === 200 || resp.status === 201) {
                Swal.fire('Success', `article data changed`, 'success');
                loadAllFiles(dateFilter).then(action => dispatch(action));
            } else {
                Swal.fire('Error', `There was a problem trying to update article data`, 'error');
            }
        });
    };

    return (
        <Box component="div" sx={{ minWidth: '90%' }}>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Stack spacing={2}>
                    <Typography
                        data-test="popuparticle-label"
                        color={theme.palette.primary.main}
                        component="div"
                        variant="h6"
                    >
                        Change article data
                    </Typography>

                    <TextField
                        data-test="popuparticle-text-name"
                        id="name"
                        name="name"
                        label="name"
                        variant="outlined"
                        autoComplete="off"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.errors.name ? String(formik.errors.name) : null}
                    />

                    <TextField
                        data-test="popuparticle-text-publisher"
                        id="publisher"
                        name="publisher"
                        label="publisher"
                        variant="outlined"
                        autoComplete="off"
                        value={formik.values.publisher}
                        onChange={formik.handleChange}
                        error={formik.touched.publisher && Boolean(formik.errors.publisher)}
                        helperText={formik.errors.publisher ? String(formik.errors.publisher) : null}
                    />

                    <TextField
                        data-test="popuparticle-text-date"
                        id="date"
                        name="date"
                        label="date"
                        variant="outlined"
                        autoComplete="off"
                        value={formik.values.date}
                        onChange={formik.handleChange}
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.errors.date ? String(formik.errors.date) : null}
                    />

                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="center"
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <Button
                            data-test="popuparticle-btn-save"
                            variant="contained"
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Save
                        </Button>
                        <Button
                            data-test="popuparticle-btn-cancel"
                            variant="outlined"
                            onClick={handleModalClose}
                            disabled={formik.isSubmitting}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </Box>
    );
};
