// redux
import { useDispatch, useSelector } from 'react-redux';

// mui
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

// actions
import { setSelectedThesaurusArticles } from '../../../../../../actions/thesaurus.actions';

// interfaces
import { Article } from '../../../../../../interfaces/article/Article.interface';
import { Selector } from '../../../../../../interfaces/Selector.interface';

interface Props {
    item: Article;
    showArticleName: boolean;
}

export const LexiconItem = ({ item }: Props) => {
    const dispatch = useDispatch();

    const { id, createdAt, fileName } = item;

    // state selector
    const selectedArticleIds = useSelector((state: Selector) => state.thesaurus.selectedArticleIds);

    // Format date to display it
    const date = new Date(createdAt);
    const options: Intl.DateTimeFormatOptions = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
    };
    const formatter = new Intl.DateTimeFormat('en-UK', options);
    const formattedDate = formatter.format(date).replace(',', ' ');

    const labelTitle: string = fileName.replace(/\.[^/.]+$/, '');
    const labelDate: string = formattedDate;

    // Update the selected article ID list
    const handleSelectFile = async (): Promise<void> => {
        const updatedSelectedArticleList: number[] = [...selectedArticleIds];
        if (selectedArticleIds.includes(id)) {
            const selectedId = updatedSelectedArticleList.indexOf(id);
            updatedSelectedArticleList.splice(selectedId, 1);
        } else {
            updatedSelectedArticleList.push(id);
        }

        dispatch(setSelectedThesaurusArticles(updatedSelectedArticleList));
    };

    return (
        <FormControlLabel
            data-test={`lexicontab-label-${labelTitle}`}
            control={
                <Checkbox
                    id={`${id}`}
                    data-test={`lexicontab-check-${labelTitle}`}
                    checked={selectedArticleIds.includes(id)}
                    onChange={() => handleSelectFile()}
                />
            }
            label={
                <Box sx={{ display: 'inline' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}>
                        {labelTitle}
                    </Typography>
                    <Typography sx={{ fontSize: '14px', display: 'inline' }}>{' - ' + labelDate}</Typography>
                </Box>
            }
        />
    );
};
