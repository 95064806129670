import { Article } from "../../interfaces/article/Article.interface";

export const filterArticlesByCreationDate = (articles: Article[], dateToFilter: Date): Article[] => {
    const filteredFiles = articles.map(article => {
        if (!article.createdAt) return { ...article, visible: false };

        const creationDate = new Date(article.createdAt);
        creationDate.setHours(0, 0, 0, 0);

        if (creationDate >= dateToFilter) {
            return { ...article, visible: true };
        } else {
            return { ...article, visible: false };
        }
    });
    return filteredFiles;
};
