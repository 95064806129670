import { types } from '../actions/actionTypes/actionTypes';
import { ConfigState } from '../interfaces/Selector.interface';


const initialState: ConfigState = {
    config: [
        { index: 0, name: 'Lexicon', data: [] },
        { index: 1, name: 'Voices', data: [] },
        { index: 2, name: 'Jingles', data: [] },
        { index: 3, name: 'Backgrounds', data: [] },
        { index: 4, name: 'Consumptions', data: [] },
        { index: 5, name: 'Listenings', data: [] }
    ],
    selectedConfig: "None"
}

export const configReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.configLoad:
            return {
                ...state,
                ...action.payload.data,
            } as ConfigState

        case types.configSetSelected:
            return {
                ...state,
                selectedConfig: action.payload.data
            } as ConfigState

        case types.configLogout:
            return initialState as ConfigState

        default:
            return state as ConfigState
    }
}