import React, { useCallback, useEffect, useState } from 'react';

// modules
import { useSelector } from 'react-redux';

// mui
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';

// interfaces
import { Selector } from '../../../../interfaces/Selector.interface';
import { Consumption, TtsConsumption } from '../../../../models/consumption.model';
import { Language } from '../../../../interfaces/article/Language.interface';

// styles
import { HeaderCell } from '../../../../theme/styled/HeaderCell';

// helpers
import api from '../../../../helpers/services/api';

interface ConsumptionDay {
    day: string;
    tts: string;
    characterConsumption: number;
    languageId: number;
    publisherName: string;
}

interface ConsumptionRow {
    tts: string;
    language: string;
    characterStreamed: number;
    characterGenerated: number;
    characterNumber: number;
    publisherName: string;
}

export const ConsumptionsTable = React.memo(() => {
    const { config } = useSelector((state: Selector) => state.config);
    const languageList = useSelector((state: Selector) => state.languages.languageList);

    const [articleGenerationCount, setArticleGenerationCount] = useState(0);
    const [longArticleGenerationCount, setLongArticleGenerationCount] = useState(0);
    const [totalCharacterStreamed, setTotalCharacterStreamed] = useState(0);
    const [totalCharacterGenerated, setTotalCharacterGenerated] = useState(0);
    const [totalCharacterNumber, setTotalCharacterNumber] = useState(0);
    const [tableRows, setTableRows] = useState([] as ConsumptionRow[]);
    const [publishers, setPublishers] = useState<string[]>([]);
    const [publisherFilter, setPublisherFilter] = useState('');

    const [startDate, setStartDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().slice(0, 10),
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString().slice(0, 10),
    );

    const refreshPublishers = useCallback((ttsConsumption: TtsConsumption[] | ConsumptionRow[]) => {
        const publishersList: string[] = [];
        for (const tts of ttsConsumption) {
            if (tts.publisherName && !publishersList.includes(tts.publisherName)) {
                publishersList.push(tts.publisherName);
            }
        }
        setPublishers(state => Array.from(new Set([...state, ...publishersList])));
    }, []);

    const handlePublisherChange = (event: SelectChangeEvent) => {
        const filterValue = event.target.value as string;
        setPublisherFilter(filterValue);
    };

    useEffect(() => {
        const consumptionConfig = config.filter(c => c.name.toLowerCase() === 'consumptions')[0].data as Consumption;
        const consumptionRows: ConsumptionRow[] = [];

        let totalCharStreamed = 0;
        let totalCharGenerated = 0;

        for (let ttsConsumption of consumptionConfig.ttsConsumptions) {
            const language = languageList.filter(l => l.id === ttsConsumption.languageId)[0] || '';
            consumptionRows.push({
                ...ttsConsumption,
                language: language.code,
                publisherName: ttsConsumption.publisherName,
            });

            totalCharStreamed += ttsConsumption.characterStreamed;
            totalCharGenerated += ttsConsumption.characterGenerated;
        }

        setTotalCharacterStreamed(totalCharStreamed);
        setTotalCharacterGenerated(totalCharGenerated);
        setTotalCharacterNumber(totalCharStreamed + totalCharGenerated);

        setArticleGenerationCount(consumptionConfig.articleGenerationCount);
        setLongArticleGenerationCount(consumptionConfig.longArticleGenerationCount);
        setTableRows(consumptionRows);
        refreshPublishers(consumptionConfig.ttsConsumptions);
    }, [config, languageList, refreshPublishers]);

    // function to handle the change of the date picker
    const handleLoadConsumption = async () => {
        let responseGenerated: Response;
        let responseStreamed: Response;

        // call the api to get the new data
        if (publisherFilter !== '' && publisherFilter !== 'null') {
            responseGenerated = await api.consumption.getConsumptionGeneratedByPublisher(
                startDate,
                endDate,
                publisherFilter,
            );

            responseStreamed = await api.consumption.getConsumptionStreamedByPublisher(
                startDate,
                endDate,
                publisherFilter,
            );
        } else {
            responseGenerated = await api.consumption.getConsumptionGenerated(startDate, endDate);
            responseStreamed = await api.consumption.getConsumptionStreamed(startDate, endDate);
        }

        const responseArticleGenerationCount = await api.consumption.getArticleGenerationCount(startDate, endDate);
        const responseLongArticleGenerationCount = await api.consumption.getLongArticleGenerationCount(
            10000,
            startDate,
            endDate,
        );
        const newConsumptionGeneratedData: ConsumptionDay[] = await responseGenerated.json();
        const newConsumptionStreamedData: ConsumptionDay[] = await responseStreamed.json();
        const articleGenerationCount: number = await responseArticleGenerationCount.json();
        const longArticleGenerationCount: number = await responseLongArticleGenerationCount.json();

        // update the state with the new data
        const newConsumptionRows: ConsumptionRow[] = [];

        for (const consumptionGeneration of newConsumptionGeneratedData) {
            let language: Language = { id: 0, code: '', enabled: true, name: '' };
            const filteredLanguage = languageList.filter(l => l.id === consumptionGeneration.languageId)[0];
            if (filteredLanguage) language = filteredLanguage;

            const existingValue = newConsumptionRows.filter(
                c => c.tts === consumptionGeneration.tts && c.language === language.code,
            )[0];
            if (existingValue) {
                newConsumptionRows.filter(
                    c => c.tts === consumptionGeneration.tts && c.language === language.code,
                )[0].characterGenerated += consumptionGeneration.characterConsumption;
            } else {
                newConsumptionRows.push({
                    tts: consumptionGeneration.tts,
                    language: language.code,
                    characterGenerated: consumptionGeneration.characterConsumption,
                    characterStreamed: 0,
                    characterNumber: 0,
                    publisherName: consumptionGeneration.publisherName,
                });
            }
        }

        for (const consumptionStreaming of newConsumptionStreamedData) {
            let language: Language = { id: 0, code: '', enabled: true, name: '' };
            const filteredLanguage = languageList.filter(l => l.id === consumptionStreaming.languageId)[0];
            if (filteredLanguage) language = filteredLanguage;

            const existingValue = newConsumptionRows.filter(
                c => c.tts === consumptionStreaming.tts && c.language === language.code,
            )[0];
            if (existingValue) {
                newConsumptionRows.filter(
                    c => c.tts === consumptionStreaming.tts && c.language === language.code,
                )[0].characterStreamed += consumptionStreaming.characterConsumption;
            } else {
                newConsumptionRows.push({
                    tts: consumptionStreaming.tts,
                    language: language.code,
                    characterGenerated: 0,
                    characterStreamed: consumptionStreaming.characterConsumption,
                    characterNumber: 0,
                    publisherName: consumptionStreaming.publisherName,
                });
            }
        }

        let totalCharStreamed = 0;
        let totalCharGenerated = 0;
        for (let consumption of newConsumptionRows) {
            consumption.characterNumber = consumption.characterStreamed + consumption.characterGenerated;
            totalCharStreamed += consumption.characterStreamed;
            totalCharGenerated += consumption.characterGenerated;
        }

        setTotalCharacterStreamed(totalCharStreamed);
        setTotalCharacterGenerated(totalCharGenerated);
        setTotalCharacterNumber(totalCharStreamed + totalCharGenerated);

        setArticleGenerationCount(articleGenerationCount);
        setLongArticleGenerationCount(longArticleGenerationCount);
        setTableRows(newConsumptionRows);
        refreshPublishers(newConsumptionRows);
    };

    return (
        <>
            <Stack
                direction="row"
                spacing={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 2 }}
            >
                <TextField
                    id="startDate"
                    label="Start date"
                    type="date"
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    id="endDate"
                    label="End date"
                    type="date"
                    value={endDate}
                    onChange={e => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel id="filter-publisher-select-label">Publisher</InputLabel>
                    <Select
                        labelId="filter-publisher-select-label"
                        id="filter-publisher-select"
                        value={publisherFilter}
                        label="Publisher"
                        onChange={handlePublisherChange}
                    >
                        <MenuItem value="">All</MenuItem>
                        {publishers?.length > 0 &&
                            publishers.map(p => (
                                <MenuItem key={`filter-publisher-menu-${p}`} value={p}>
                                    <Stack direction="row">{`${p}`}</Stack>
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <Button
                    id="btn-validate"
                    defaultValue="Validate"
                    variant="outlined"
                    className="button"
                    name="button validate"
                    sx={{ fontWeight: 'bold' }}
                    onClick={handleLoadConsumption}
                >
                    Filter
                </Button>
            </Stack>

            <TableContainer component={Paper}>
                <Table aria-label="Consumptions-table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <HeaderCell>TTS</HeaderCell>
                            <HeaderCell>Language</HeaderCell>
                            <HeaderCell>Streamed Characters</HeaderCell>
                            <HeaderCell>Generated Characters</HeaderCell>
                            <HeaderCell>Total Character Volume</HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows
                            .sort((a, b) => (a.tts > b.tts ? 1 : -1))
                            .map(item => {
                                return (
                                    <TableRow key={`consumption-table-${item.tts}-${item.language}`}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>{String(item.tts)}</TableCell>
                                        <TableCell>{String(item.language)}</TableCell>
                                        <TableCell>{item.characterStreamed.toLocaleString('fr')}</TableCell>
                                        <TableCell>{item.characterGenerated.toLocaleString('fr')}</TableCell>
                                        <TableCell>{item.characterNumber.toLocaleString('fr')}</TableCell>
                                    </TableRow>
                                );
                            })}
                        <TableRow key={`consumption-table-total.tts`}>
                            <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                {totalCharacterStreamed.toLocaleString('fr')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                {totalCharacterGenerated.toLocaleString('fr')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                {totalCharacterNumber.toLocaleString('fr')}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Stack spacing={0} display="flex" sx={{ mb: 0 }}>
                <Box maxWidth="lg" m={2} pt={3}>
                    <Typography component="span" variant="h6" color="primary">
                        Number of articles generated:&nbsp;
                    </Typography>

                    <Typography component="span" variant="h6" color="secondary">
                        {String(articleGenerationCount)}
                    </Typography>
                </Box>
                <Box maxWidth="lg" ml={2}>
                    <Typography component="span" variant="h6" color="primary">
                        Including long articles (+10k characters):&nbsp;
                    </Typography>

                    <Typography component="span" variant="h6" color="secondary">
                        {String(longArticleGenerationCount)}
                    </Typography>
                </Box>
            </Stack>
        </>
    );
});
