import { ArticlePart, ArticleSectionType } from "../interfaces/article/ArticlePart.interface";
import { Article } from "../interfaces/article/Article.interface";
import { EditorContext } from "../interfaces/editor/EditorContext.interface";
import { defaultVoice } from "./defaultVoice";
import { defaultLanguage } from "./defaultLanguage";

/**
 * Article editorPage initial state.
 * @type ArticleContent
 */
export const editorInitialParts: ArticlePart[] = [
    {
        id: 0,
        order: 0,
        voice: defaultVoice,
        content: "Title text",
        subtitleText: null,
        contentHtml: "Title text",
        contentHtmlOriginal: "Title text",
        prosodyPitch: 1,
        prosodyRate: 1,
        prosodyVolume: 1,
        prosodyContour: '',
        pauses: [],
        emotion: { emotionStyle: "", emotionDegree: "NONE" },
        replacementTexts: [],
        hasSyntaxError: false,
        visible: true,
        type: ArticleSectionType.TITLE,
        language: defaultLanguage,
        ttsModel: ""
    },
    {
        id: 1,
        order: 1,
        voice: defaultVoice,
        content: "Content part",
        subtitleText: null,
        contentHtml: "Content part",
        contentHtmlOriginal: "Content part",
        prosodyPitch: 1,
        prosodyRate: 1,
        prosodyVolume: 1,
        prosodyContour: '',
        pauses: [],
        emotion: { emotionStyle: "", emotionDegree: "NONE" },
        replacementTexts: [],
        hasSyntaxError: false,
        visible: true,
        type: ArticleSectionType.CONTENT,
        language: defaultLanguage,
        ttsModel: ""
    }
]

const defaultSoundEffect = {
    id: -1,
    defaultVolume: 0,
    url: "",
    volume: 0
}

export const editorInitialArticle: Article = {
    audioMediaFile: null,
    author: "-",
    backgroundMusicEffect: defaultSoundEffect,
    createdAt: Date.now(),
    fileName: '',
    generationLog: '',
    id: -1,
    imageUrl: '',
    introActivated: false,
    introMusicEffect: defaultSoundEffect,
    isSelected: false,
    language: { code: 'fr-FR', name: 'French', enabled: true, id: 1 },
    lastModifiedBy: '',
    lastModifiedDate: '',
    name: '',
    originalArticleId: -1,
    outroMusicEffect: defaultSoundEffect,
    publisherPublicationDate: null,
    publisher: '',
    publisherTitle: '',
    selectedVersion: true,
    status: 'PENDING',
    titleMusicEffect: defaultSoundEffect,
    tone: { id: -1, name: '-' },
    topic: '',
    updatedAt: Date.now(),
    userUploadedId: 1,
    videoMediaFile: null,
    visible: true,
}

export const editorInitialState: EditorContext = {
    activeArticle: editorInitialArticle,
    editor: {
        articleLastModified: '',
        articleLastModifiedBy: '',
        articleName: '',
        audioFile: '',
        audioUrl: '',
        hasPartWithSymbolsOnly: false,
        hasSyntaxError: false,
        isDownloadable: false,
        isHighlightModeActivated: false,
        isModified: false,
        isSaving: false,
        loadAudioFile: false,
        introMusicId: -1,
        titleMusicId: -1,
        outroMusicId: -1,
        backgroundMusicId: -1,
        selectedLanguage: 'fr-FR',
        selectedPart: 0
    }
}
