import { types } from '../actions/actionTypes/actionTypes';
import { SidebarState } from '../interfaces/Selector.interface';

const initialState: SidebarState = {
    selectedTab: 'editor',
    selectedTabName: 'Audio',
    dateFilter: 'today',
}

export const sidebarReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.sidebarTabChange:
            return {
                ...state,
                ...action.payload.data
            }

        case types.sidebarSetDateFilter:
            return {
                ...state,
                dateFilter: action.payload.data
            }

        default:
            return state
    }
}